<template>
  <div :style="{width:Display,opacity:Opacity}" class="visualizer">
    <div 
      draggable="false"
      @touchstart="dragStart"
      @mousedown="dragStart"
      @mouseup ="dragEnd"
      @touchend="dragEnd"
      @mousemove="dragging"
      @touchmove="dragging"
      :style="{ left:Position,transition:transition, width:(100*elts.length)+'%' }"
      class="visualContent"
    >
    <div v-for="(elt,index) in elts" :key="index"
    :class="(ImgWidth == 'auto' || ImgWidth.slice(0,-1) < 100) ? 'visualCenter' : 'visualStart'"
    ><img 
      loading="lazy"
      :style="{width:ImgWidth,maxWidth:ImgWidthM,minWidth:ImgWidth}"
      draggable="false" :src="elt" class="visualImg1" />
      
    </div>
    </div>
    <div class="visualPoints">
      <span v-for="(elt,index) in elts" :key="index"></span>
    </div>
    <AndroidButton @clicked="DisplayDesc=true" v-show="description !='' && !DisplayDesc" class="visualInfos" radius="3em" >
      <img src="./img/help.svg" />
    </AndroidButton>
    <AndroidButton @clicked="DisplayDesc=false" v-show="description !='' && DisplayDesc" class="visualInfos1" radius="0.4em" >
      <span>{{ description }} </span> 
    </AndroidButton>
    <AndroidButton @clicked="toWhatsapp" class="visualInfosW" radius="4em" >
      <img src="./img/whatsapp.svg"> 
    </AndroidButton>
    <AndroidButton radius="4em" @clicked="close" class="visualCross"><img src="./img/more.svg"/></AndroidButton>
  </div>
</template>
<style src="./visualizer.css">
</style>
<script src="./visualizer.js">
</script>