<template>
    <div ref="main" @click="cliked" v-show="Display" class="adminFormCont">
        <div class="adminForm">
            <h3 class="adminFormHead">Add new Quizz</h3>
            <textarea v-model="question" placeholder="Question" ></textarea>
            <input v-for="(res, index) in response" v-model="res.text" :key="index" 
                type="text" :placeholder="'Reponse'+(index+1)" />
            <span>Choisir la bonne réponse.</span>
            <select v-model="trueResponse">
                <option v-for="(res, index) in response" :key="index" >{{ res.text }}</option>
            </select>
            <AndroidButton @clicked="save" radius="0.4em" class="adminFormButt">Enregistrer</AndroidButton>
        </div>
        <LLoading1 :visible="loading"/>
    </div>
</template>
<style>
.adminFormCont {
  position: fixed;
  left: 0%;
  top:0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.63);
  display: flex;justify-content: center;align-items: center;
  text-align: center;
}
.adminForm {
    display: flex;align-items: center;
    flex-direction: column;
    width: 300px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    background-color: white;
    padding: 20px;
    padding-top: 10px;
}
.adminForm h3 {
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px;
    font-weight: normal;
}
.adminForm span {
    font-size: 13px;
    margin-bottom: 5px;
}
.adminForm > input, .adminForm > textarea, .adminForm > select {
    width: 100%;
    height: 35px;
    min-height: 35px;
    border-radius: 0.4em;
    padding-left: 10px;
    border: solid 1px #212121;
    margin-bottom: 10px;
}
.adminForm > textarea {
    padding: 10px;
    height: 60px;
    min-height: 60px;
}
.adminForm > .adminFormButt {
  padding: 20px;
  padding-top:10px;
  padding-bottom:10px;
  font-size: 13px;
  text-align: center;
  background-color: #212121;
  color:white;
  cursor: pointer;
}
</style>
<script>
import LLoading1 from '@/components/global/lLoading1/LLoading1'

export default {
  name: 'admin',
  props: {
    password:  String
  },
  components: {
    LLoading1
  },
  emits:['saved'],
  data () {
    return {
        Display:false,
        question:'',
        trueResponse:'',
        response:[{text:''},{text:''},{text:''},{text:''}],
        loading:false,
        index:-1,
        id:-1,
        type:'add'
    }
  },
  methods: {
    cliked (event) {
      if( event.target == this.$refs.main) this.close()
    },
    show (inf,index) {
      this.Display = true
      if(inf === null) {
        this.type = 'add'
        return
      }
      this.type = 'modify'
      this.question = inf.question
      this.response[0].text = inf.res1
      this.response[1].text = inf.res2
      this.response[2].text = inf.res3
      this.response[3].text = inf.res4
      this.trueResponse = inf.trueRes
      this.id = inf.id
      this.index = index
    },
    close () {
      if(this.type == 'modify') this.clear()
      this.Display=false
    },
    clear () {
      this.response = [{text:''},{text:''},{text:''},{text:''}]
      this.question = ''
      this.trueResponse = ''
    },
    save () {
        if(this.question.length < 1) {
          alert('Entrez une question correcte')
          return
        }
        let count = 0
        count = this.response[0].text.length < 1 ? count : count +1
        count = this.response[1].text.length < 1 ? count : count +1
        count = this.response[2].text.length < 1 ? count : count +1
        count = this.response[3].text.length < 1 ? count : count +1
        if(count < 2 ) {
          alert('il faut au moins 2 réponses possibles !')
          return
        } 
        if(this.trueResponse.length < 1) {
          alert('Entrez une réponse correcte')
          return
        }
        if( this.trueResponse != this.response[0].text &&
            this.trueResponse != this.response[1].text &&
            this.trueResponse != this.response[2].text &&
            this.trueResponse != this.response[3].text
          ) {
           alert('Entrez une réponse correcte')
          return
        }

        this.loading = true 
        const axios = require('axios')
        axios.post(this.$store.state.baseUrl + 'admin.php',{
            password:this.password,
            type: this.type == 'add' ? 'create' : 'modify',
            id:this.id,
            question:this.question,
            res1: this.response[0].text.length < 1 ? '' : this.response[0].text,
            res2: this.response[1].text.length < 1 ? '' : this.response[1].text,
            res3: this.response[2].text.length < 1 ? '' : this.response[2].text,
            res4: this.response[3].text.length < 1 ? '' : this.response[3].text,
            trueRes:this.trueResponse
        })
        .then(({data}) => {
          console.log(data)
          if(data == 'wrongPassword') alert('error')
          else if (this.type == 'add') this.$emit('saved', {type:'add',inf:data[0]})
          else this.$emit('saved', {type:'modify',inf:{
            id:this.id,
            question:this.question,
            res1: this.response[0].text,
            res2: this.response[1].text,
            res3: this.response[2].text,
            res4: this.response[3].text,
            trueRes:this.trueResponse,
            index: this.index,
          }})
          this.loading = false
          this.clear()
          this.close()
        })
        .catch((error) => {
          console.log(error)
          this.loading = false  
        })
    },
  }
}

</script>
