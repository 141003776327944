<template>
    <div ref="main" @click="cliked" v-show="Display" class="profile1Cont">
        <div class="profile1Head">
            <div v-show="$store.state.user.connected" class="profile1">
                <div :style="{backgroundImage:'url('+
                ($store.state.user.img != '' ? bUrl+$store.state.user.img : require('./img/user.svg'))
                +')'}"  
                class="profile1Img">
                    <label :for="loadProfile ? '': 'uploaderlandingSendImg0'" >
                        <img src="./img/exchange.svg"/>
                    </label>
                    <ImgUpload @imgInfos="changeProfile" v-show="false" id="0" name="landingSendImg" />
                    <LLoading1 :visible="loadProfile" style="border-radius:10em" />
                </div>
                <div class="profile1Infos">
                    <span>{{ $store.state.user.name.toUpperCase() }}</span>
                    <AndroidButton @clicked="getRanks" class="profile1Number">{{ $store.state.user.pts }} pts 
                        <span style="color:rgb(90,90,90)">- {{ rank }} <i>{{ rank == 1 ? 'er' : 'ème' }}</i>/{{ total }}</span>
                    </AndroidButton>
                    <AndroidButton @clicked="deconnexion" radius="0.4em" class="profile1Out"><img src="./img/logout1.svg"/></AndroidButton>
                </div>
            </div>
            <AndroidButton @clicked="share" radius="0.4em">Partagez et gagnez 2000 points<img src="./img/share.svg" /></AndroidButton>
            <div v-show="DisplayShare" class="profile1ShareLink">{{ $store.state.baseUrl+'token/'+$store.state.user.shareToken }}</div>
            
            <AndroidButton @click="seeRank" radius="0.4em">Voir le classement<img src="./img/ranking.svg" /></AndroidButton>
             <div v-show="DisplayRank" class="profile1Rank">
                <div v-for="(rank,index) in ranking" :key="index" :class="setColor(index)"
                :style="{ backgroundColor:index%2==1 ? 'transparent' : 'rgba(200, 200, 200, 0.3)' }"   >
                    <div>{{ index + 1}}</div><span>{{ rank.name }}</span><p>{{ rank.points }} pts</p>
                </div>    
            </div>
            <div v-show="DisplayRank" class="profile1Gift"> Distributions de cadeaux chaque mois aux 10 premiers. </div>

            <LLoading1 :visible="loading" style="z-index:3"/>
        </div>
            
        <Visualizer style="z-index:3" ref="visualizer"/>
        <Reload ref="reload" style="z-index:3"/>
        <Confirm style="z-index:3" ref='confirm' @clicked="disconnect"/>
    </div>
</template>
<style src="./profile.css">
</style>
<script src = "./profile.js">
</script>
