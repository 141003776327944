<template>
    <div class="jNavbar">
        <div class="jNavbarLogo">
                <div class="jNavbarLogo1">
                    <img style="display:none" src='./img/home.svg'/>
                    sassa<span class="jNavbarLColor" style="font-weight:bold;">yer.com</span>
                </div>
                <div v-show="connected">
                    @{{ $store.state.user.name }}
                </div>
                <div v-show="!connected">
                    #Rechercher
                </div>
        </div>
        <div class="jNavbarTop">
            <div class="jNavbarSearch"><SearchBar ref="search" @quickSearch="quickSearch" @search="search" :placeholder="placeholder" /></div>
           <div class="jNavbarMenus">
                <div @click="$emit('messages')" v-show="false" class="jNavbarMenu">
                    <div class="jNavBarMsg">
                        <img src="./img/msg1.svg"/>
                        <span v-show="$store.state.unreadCount.overall > 0" >
                            {{ $store.state.unreadCount.overall }}
                        </span>
                    </div>
                    Messages
                </div>
            </div>
            <SearchButt :selected='elts.objectList' @clicked="change('objectList')" />
            <Favorite :selected='elts.favorite' @clicked="change('favorite')" />  
            <div v-show="connected" class="jNavbarMenus">
                <AndroidButton radius="0.4em" @clicked="$refs.profile.show()" class="jNavbarMenu">
                    <img src="./img/logout.svg"/>
                    Profile
                </AndroidButton>
            </div>
            <div v-show="!connected" class="jNavbarMenus">
                <AndroidButton radius="0.4em" @clicked="$refs.connexion.show()" class="jNavbarMenu">
                    <img src="./img/login.svg"/>
                    Connexion
                </AndroidButton>
            </div>  
            <div v-show="!connected" class="jNavbarMenus">
                <AndroidButton radius="0.4em" @clicked="$refs.inscription.show()" class="jNavbarMenu">
                    <img src="./img/user.svg"/>
                    Inscription
                </AndroidButton>
            </div>
        </div>
    </div>
    <!-- navbar version for smaller screen -->
    <div class="jNavbar1">
        <div class="jNavbar1Logo">
            <div>sass
                <span class="jNavbarLColor" style="font-weight:bold;">ayer</span>
            </div>
            <span style="font-size:13px; text-align:right" v-show="!connected">Favoris</span>
            <span style="font-size:13px; text-align:right" v-show="connected">@{{$store.state.user.name}}</span>
        </div>
        <div class="jNavbar1Top">
            <div class="jNavbar1Menu">
                <p><SearchButt1 :selected='elts.objectList' @clicked="change('objectList')" /></p>
                <p><Favorite1 :selected='elts.favorite' @clicked="change('favorite')"/></p>
                <!-- <div v-show="!connected"><img @click="$emit('inscription')" src="./img/user.svg"/></div> -->
                <div v-show="!connected">
                    <AndroidButton @clicked="$refs.connexion.show()" radius="0.4em" ><img src="./img/login.svg"/></AndroidButton>
                </div>
                <div v-show="connected">
                    <AndroidButton @clicked="$refs.profile.show()" radius="0.4em" ><img src="./img/logout.svg"/></AndroidButton>
                </div>
                <div v-show="false">
                    <div @click="$emit('messages')" class="jNavBarMsg">
                        <img src="./img/msg1.svg"/>
                        <span v-show="$store.state.unreadCount.overall > 0" >
                            {{ $store.state.unreadCount.overall }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div><SearchBar1 ref="search1" @quickSearch="quickSearch" @search="search" :placeholder="placeholder" /></div>
    </div>
    <Quizz ref='quizz' @clicked="$refs.quizzForm.show()" @closing="closeQuizz" style="z-index:1"/>
    <QuizzForm ref='quizzForm' @results="quizzing" style="z-index:3"/>
    <Profile ref='profile' style="z-index:3"/> 
    <Inscription ref='inscription' @connexion='$refs.connexion.show()' style="z-index:3"/>
    <Connexion ref='connexion' @inscription='$refs.inscription.show()' style="z-index:3"/>
    <Geolocation /> 
</template>
<style src="./jNavbar.css">
</style>
<script src = "./jNavbar.js">
</script>
