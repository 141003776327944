<template>
    <div ref="main" @click="cliked" v-show="$store.state.DisplayQuizz" class="quizzFormCont">
      <div class="quizzForm">
        <h4>Question à {{ points }} points</h4>
        <div class="quizzFormText">{{ infos.question }}</div>
        <AndroidButton v-for="(qz,index) in quizzs"  @clicked="chose(index)" :key="index" radius="0.4em" class="quizzFormRes">
          <div>
            <div><img v-show="qz.chosen" src="./img/checked.svg"/></div><span> {{ qcm[index] }}. {{ qz.text }}
            </span>
          </div>
        </AndroidButton>
        <div class="quizzFormButt"><AndroidButton @clicked="save" radius="0.4em" >Répondre</AndroidButton></div>
        <LLoading :visible="loading"/>
      </div>
      <AlertG ref="alertG" class="alertGF"/>
      <AlertG ref="alertGS" @okay="close" class="alertGS"/>
      <AlertG ref="alertGF" @okay="close" class="alertGF"/>
    </div>
</template>
<style>
.quizzFormCont {
  position: fixed;
  left: 0px;
  top:0px;
  width: 100%;
  height: 100%;
  display: flex;justify-content: center;align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.1);
}
.quizzForm {
  position:relative;
  background-color: white;
  width: 300px;
  max-height: 90%;
  max-width: 90%;
  overflow: auto;
  line-height: 21px;
  border-radius: 0.4em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  padding: 20px;
  padding-bottom: 0px;
}
.quizzForm > h4 {
  margin: 0px;
  line-height: 21px;
  text-align: center;
}
.quizzForm > h4 {
  margin: 0px;
  line-height: 21px;
  text-align: center;
}
.quizzFormText {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 10px;
}
.quizzForm > .quizzFormRes {
  font-size: 14px;
  padding-top:15px ;
  padding-bottom: 15px;
  display: flex;justify-content: flex-start;
}
.quizzFormRes > div {
  display: flex; justify-content: flex-start;align-items: center;
}
.quizzFormRes > div > div { 
  padding:0px;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 20px;
  border-radius: 3em;
  display: flex;justify-content: center;align-items: center;
  border:solid 2px var(--mainColor);
}
.quizzFormRes > div > div > img {
  width: 20px;
}
.quizzFormButt {
  margin-top:20px;
  text-align:center;
  padding-bottom:20px;
}
.quizzFormButt .androidButton {
  background-color: #212121;
  padding:10px;
  color:white
}


.alertGS {
  background-image:url('img/star2.gif');
}
.alertGS .alertGBox {
  padding-top:20px ;
}
.alertGS h3 {
  display: none;
}
.alertGF {
}
.alertGF .alertGBox {
  padding-top:20px ;
}
.alertGF h3 {
  display: none;
}
</style>
<script>
import LLoading from '@/components/global/lLoading/LLoading'

export default {
    name: 'quizz',
    components: {
      LLoading
    },
    emits:["results"],
    data () {
        return {
            loading:false,
            Display:false,
            infos:{},
            points:0,
            quizzs:[{text:'.',chosen:false},{text:'..',chosen:false},{text:'...',chosen:false}],
            qcm:['A','B','C','D'],
            chosen:''
        }
    },
    methods: {
      cliked (event) {
        if( event.target == this.$refs.main) this.close()
      },
      show () {
        this.infos = this.$store.state.quizzs[0]
        this.points = this.$store.state.quizzPoints[this.infos.points]
        let responses = []
        if(this.$store.state.quizzs[0].res1 != '') responses.push({text:this.$store.state.quizzs[0].res1,chosen:false})
        if(this.$store.state.quizzs[0].res2 != '') responses.push({text:this.$store.state.quizzs[0].res2,chosen:false})
        if(this.$store.state.quizzs[0].res3 != '') responses.push({text:this.$store.state.quizzs[0].res3,chosen:false})
        if(this.$store.state.quizzs[0].res4 != '') responses.push({text:this.$store.state.quizzs[0].res4,chosen:false})
        this.quizzs = responses
        this.$store.commit('setDisplayQuizz',true)
      },
      close() {
        this.infos = {}
        this.quizzs = []
        this.chosen = ''
        this.loading = false
         this.$store.commit('setDisplayQuizz',false)
      },
      chose (index) {
        let i=0, max = this.quizzs.length
        for(i=0;i<max; i++) {
          this.quizzs[i].chosen = false
        }
        this.quizzs[index].chosen = true
        this.chosen = this.quizzs[index].text
      },
      save () {
        if(!this.$store.state.user.connected) {
          this.$store.commit('setLogDisplay',{
            inscription:false,
            connexion:true
          })
          return
        }
        if(this.chosen == '') {
          this.$refs.alertG.displaying('Aucune réponse sélectionée !')
          return
        }

        this.loading = true
        setTimeout(()=> {
          if(this.chosen == this.infos.trueRes) {
            this.$emit('results','success')
            this.$store.commit('setUserPts',this.$store.state.user.pts+this.points)
            this.$refs.alertGS.displaying(`<b>Félicitation !!!!</b>
            <div style="font-size:30px;margin-top:20px" >+${this.points} points</div>`)
          }
          else  {
             this.$emit('results','failure')
            let i=0, max = this.quizzs.length, q = ''
            for(i=0;i<max; i++) {
              if(this.quizzs[i].text == this.infos.trueRes) {
                q = this.qcm[i]
                break
              }
            }
              this.$refs.alertGF.displaying(`
                <span style="font-weight:bold;color:rgb(238, 64, 64)" >ECHEC !!</span>
                <div style="margin-top:10px">La bonne réponse:</div>
                <div style="margin-top:10px;font-weight:bold;">${q}: ${this.infos.trueRes}</div>
              `)
          }
          this.loading = false
        },1100)
      }
    }
  }
  
</script>
