import SearchBar from './SearchBar'
import SearchBar1 from './SearchBar1'
import SearchButt from './button/SearchButt'
import SearchButt1 from './button/SearchButt1'
import Favorite from './button/Favorite'
import Favorite1 from './button/Favorite1' 
import Inscription from '@/components/inscription/Inscription'
import Connexion from '@/components/inscription/Connexion'
import Geolocation from './Geolocation'
import Profile from '@/components/profile/Profile'
import Quizz from '@/components/global/quizz/Quizz'
import QuizzForm from '@/components/global/quizz/QuizzForm'

export default {
  components: {
    SearchBar,
    SearchBar1,
    SearchButt,
    SearchButt1,
    Favorite,
    Favorite1,
    Inscription,
    Connexion,
    Geolocation,
    Profile,
    Quizz,
    QuizzForm
  },
  name: 'jNavBar',
  props: {
    state: {
      type: String,
      default: 'none'
    }
  },
  emits:["inscription","connexion","search","messages"],
  data () {
    return {
      elts: {objectList:true,favorite:false},
      current: 'objectList',
      connected:false,
      placeholder:'Rechercher un objet'
    }
  },
  methods: {
    change (elt) {
      this.$router.push({name:elt})
      //---- to delete
      this.elts[elt] = true
      this.elts[this.current] = false
      this.current = elt
    },
    change1 (elt) {
      if(!this.elts[elt]) {
        this.elts[elt] = true
        this.elts[this.current] = false
        this.current = elt
        this.$router.push({name:elt})
      }
    },
    quickSearch (toSearch) {
      this.$store.commit('setSearch',{page:'favorite',search:toSearch})
    },
    search (toSearch) {
      this.$store.commit('setSearch',{page:this.current,search:toSearch})
    },
    loadLocStorage (key,commit) {
      let ls = localStorage.getItem(key)
      if(ls != null ) {
        try {
          let inf = JSON.parse(ls)
          //console.log(inf)
          //inf = inf.filter(tk => tk != null)
          //inf = key == 'stockerSetStock' ? inf.slice(0,300) : inf
          this.$store.commit(commit,inf)
        }
        catch (error) {
          console.log(error)
          this.$store.commit(commit,[])
        }
      }
    },

    getInfos () {
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'favorite.php', {
        type:"getInfos",
      })
      .then(({data}) => {
        //console.log(data)
        this.$store.commit('setQuizz',data)
        this.showQuizz()
      })
      .catch((error) => {
        console.log(error)
      })
    },
    solveQuizz (code) {
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'favorite.php', {
        userId: this.$store.state.user.id,
        code:code,
        type:"solveQuizz",
      })
      .then(({data}) => {
        console.log(data)
      })
      .catch((error) => {
        console.log(error)
      })
    },
    showQuizz () {
      let k = this
      if(this.$store.state.quizzs.length<1) {
        setTimeout(()=>{
          k.getInfos()
        },30000)
        return
      }
      setTimeout(()=>{
        k.$refs.quizz.show()
      },5000)
    },
    quizzing (result) {
      if(result == "success") {
        this.solveQuizz(this.$store.state.quizzs[0].code)
      }
      let quizzs = [...this.$store.state.quizzs]
      quizzs.shift()
      this.$store.commit('setQuizz',quizzs)
      this.$refs.quizz.close()
      this.showQuizz()
    },
    closeQuizz () {
      let quizzs = [...this.$store.state.quizzs]
      quizzs.shift()
      this.$store.commit('setQuizz',quizzs)
      this.showQuizz()
    }
  },
  watch: {
    '$store.state.toSearch' (newVal) {
      if(newVal.page==="objectListSender") {
        this.$refs.search.search = newVal.search
        this.$refs.search1.search = newVal.search
      }
    },
    '$store.state.user.connected' (newVal) {
      this.connected = newVal
    },
    '$route' (to/*, from*/) {
        this.placeholder = to.name == 'objectList' ? 'Rechercher un objet' : 'Rechercher dans vos boutiques favorites'
        this.change1(to.name)
    }
  },
  mounted () {
    //-------check if connected ------------------------------
    let ls = localStorage.getItem('user__')
    if(ls != null ) { 
      let inf = JSON.parse(ls)
      this.$store.commit('connexion',{...inf,connected: true})
    }
    //---------- load favorite ----------------------------------
    this.loadLocStorage('favorites__','setFavorites')
    //-----------------------------------------
    this.getInfos()
    /*let k = this
    setInterval(()=>{
      k.$refs.quizz.show()
    },4000)*/
  }
}
