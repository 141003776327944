import LLoading1 from '@/components/global/lLoading/LLoading'
import Visualizer from '@/components/global/visualizer/Visualizer'
import Reload from '@/components/global/reload/Reload'
import Choose from '@/components/global/choose/Choose'

export default {
  name: 'shopArticle',
  components: {
    LLoading1,
    Visualizer,
    Reload,
    Choose
  },
  data () {
    return {
      Display:false,
      loading:false,
      infos:{},
      list:[],
      listAll:[],
      categories:[],
      word:'',
      catSearch:{cat:-1,categorie:''},
      nbrRow:1
    }
 },
 computed: {
 },
 methods: {
  show (infos) {
    this.infos = infos
    this.Display = true
    this.getProducts()
  },
  close () {
    if(this.$refs.visualizer.Opacity==1 || this.$refs.reload.Display == true) {
      this.$refs.visualizer.close()
      this.$refs.reload.close()
      return
    }
    this.infos = {}
    this.list = []
    this.listAll = []
    this.$refs.choose.reset()
    this.word = ''
    this.nbrRow = 1
    this.Display = false
  },
  searchText () {
    this.textSearch = this.removeAccents(this.word)
    this.search()
  },
  searchCat (cat) {
    this.catSearch = cat
    this.search()
  },
  search () {
    let k = this
    let listAll = this.listAll
    let w = k.removeAccents(k.word.toLowerCase())
    this.list = listAll.filter ((prod)=>{
      let isCat = k.catSearch.cat == -1 ? true : ( k.catSearch.cat == prod.cat ? true:false)
        
      let pd = k.removeAccents(prod.nomProduit.toLowerCase())
      let isProd = pd.includes(w)

      if( isCat && isProd) return true
      return false
    })
  },
  removeAccents (str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
  },
  setImages (inf) {
    let images =  []
    let bUrl = this.$store.state.stockwareUrl + 'images/'
    if(inf.produitImage0 != '' ) images.push(bUrl+inf.produitImage0)
    if(inf.produitImage1 != '' ) images.push(bUrl+inf.produitImage1)
    if(inf.produitImage2 != '' ) images.push(bUrl+inf.produitImage2)
    if(inf.produitImage3 != '' ) images.push(bUrl+inf.produitImage3)
    return images
  },
  getProducts () {
    //console.log(this.infos.session+this.infos.owner)
    let shopInf = this.$store.state.shopArticles[(this.infos.session+this.infos.owner)]
    if( shopInf === undefined) this.loading = true
    else {
      this.list = shopInf.articles
      this.categories = shopInf.categories
      this.listAll = this.list
      return false
    }
    
    const axios = require('axios')
    axios.post(this.$store.state.stockwareUrl + 'api/admin/sassayerProdS.php', {
      type:"productList",
      owner:this.infos.owner,
      session:this.infos.session
    })
    .then(({data}) => {
      //data.products = data.products.concat(data.products)
      //console.log(data)
      //------ set list --------
      this.categories = [{cat:-1,categorie:'Toutes catégories'},{cat:null,categorie:'Aucune'},...data.categories]
      let products = data.products
      let list = [], max = products.length, i=0
      for(i=0;i<max;i++) {
        let imgs = this.setImages(products[i])
        list.push({...products[i],images:imgs})
      }
      this.list = list
      this.listAll = list
      this.$store.commit('setShopArticles',{
        session:this.infos.session,
        owner: this.infos.owner,
        articles: list,
        categories:this.categories
      })
      this.loading = false
    })
    .catch((error) => {
      this.$refs.reload.show()
      console.log(error)
      this.loading = false
    })
  },
 }
}
