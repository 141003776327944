<template>
    <div class="choose">
        <input v-model="current[keyName]" :placeholder="placeholder"/>
        <select :disabled="readOnly" @change="change" v-model="option">
            <option  v-for="(opt,index) in infos" :value="index" :key="index" >{{ opt[keyName] }}</option>
        </select>
    </div>
</template>
<style src="./choose.css">
</style>
<script src = "./choose.js">
</script>
