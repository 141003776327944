<template>
    <div v-show="Display" class="alertG">
        <div class="alertGBox">
            <h3 class="alertGtitle">Infos</h3>
            <div v-html="text"></div>
            <div class="alertGbutton"><AndroidButton radius="0em" @clicked="clicked" >OK</AndroidButton></div>
        </div>
    </div>
</template>
<style src="./alertG.css">
</style>
<script src = "./alertG.js">
</script>
