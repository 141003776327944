<template>
    <div :style="{right:Right,opacity:Opacity}" class="quizz">   
      <AndroidButton @clicked="$emit('clicked')" radius="0em" class="quizzLeft">
        <img src="./img/gift.svg" />
        <span>{{ points }}</span>
      </AndroidButton>
      <AndroidButton @clicked="$emit('clicked')" radius="0em" class="quizzCenter">
        <div>Gagner des cadeaux</div>
        <span>A la fin de chaque mois</span>
        <span>PRIX: {{ points }} points</span>
      </AndroidButton>
      <AndroidButton @clicked="close1" radius="0em" class="quizzRight">
        <img src="./img/cross.svg" />
      </AndroidButton>
    </div>
</template>
<style src="./quizz.css">
</style>
<script src="./quizz.js">
</script>
