<template>
    <div class="admin">
        <h3 class="adminHead">Welcome to admin !!!</h3>
        <div>
            <h4 @click="getStat">Statistiques</h4>
            <div v-show="showStat" class="adminStats">
                <div style="font-weight:bold"><b>Nombre d'inscrit:</b> {{ amountUser }}</div>
                <div>
                    <b>Nombre de recherche par jour</b>
                    <div class="adminSTable">
                        <div><span>Jour</span><div>Nbr_rech</div></div>
                        <div v-for="(ds,index) in daySearchs" :key="index" >
                            <span>{{ setDate(ds.date) }}</span><div>{{ds.amount}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <h4 @click="getInfos" >Quizz list</h4>
            <div v-show="showQuizz" class="adminAdd">
                <AndroidButton @clicked="this.$refs.form.show(null)" radius="0.4em">
                    <img src="./img/plus.svg"/>
                </AndroidButton>
                <span>Ajouter un Quizz</span>
            </div>
            <div v-for="(acc,index) in quizzs" :key="index" class="adminElt">
                <div class="adminEltInfos">
                    <div>
                        <span class="adminCity">{{ acc.question.slice(0,100) }}</span>
                    </div>
                </div>
                <AndroidButton @clicked="this.$refs.form.show(acc,index)" 
                    radius="5em" class="adminEltModif">
                    <img src="./img/pencil.svg"/>
                </AndroidButton>
                <AndroidButton @clicked="deleteMe(acc,index)" radius="5em" class="adminEltMenu">
                    <img src="./img/cross.svg"/>
                </AndroidButton>
            </div>
        </div>
        <Form ref='form' @saved="saved" :password="password" />
    </div>
</template>
<style src="./admin.css">
</style>
<script src = "./admin.js">
</script>
