export default {
  name: 'choose',
  props: {
    placeholder: {
      type:String,
      default:'Catégorie'
    },
    infos: {
      type: Array,
      default:[{name:'Chaussure'},{name:'Hauts'},{name:'Pantalon de la mor qui tue'}]
    },
    keyName: {
      type:String,
      default:'name'
    },
    byDefault: {
      type:Object,
      default:{}
    },
    readOnly: false
  },
  data () {
    return {
      current:'',
      option:''
    }
  },
  emits:['chosen'],
  methods: {
    change (event) {
      this.current = this.infos[event.target.value]
      this.$emit('chosen',this.current)
    },
    reset () {
      let newCur = []
      newCur[this.keyName] = ''
      this.current = newCur
      this.option = ''
    },
    setCurrent (index) {
      this.current = this.infos[index]
    }
  },
  watch: {
    byDefault (newVal) {
      if(this.byDefault != null && this.byDefault != {} ) {
        this.current = newVal
        this.$emit('chosen',this.current)
      }
    }
  },
  mounted () {
    if(this.byDefault != null && this.byDefault != {} ) {
      this.current = this.byDefault
    }
  }
}
