import JNavbar from '@/components/global/jNavbar/JNavbar'
import JFooter from '@/components/global/jFooter/JFooter'
import Service from '@/components/service/Service'
import LLoading1 from '@/components/global/lLoading1/LLoading1'
import ScrollTop from '@/components/global/scrollTop/ScrollTop'
import InputSearch from '@/components/global/inputSearch/InputSearch'
//import ChatBox from '@/components/global/chatBox/ChatBox'
import Item from '@/components/item/Item'
import ShopArticle from '@/components/shopArticle/ShopArticle'
import Visualizer from '@/components/global/visualizer/Visualizer'
import Reload from '@/components/global/reload/Reload'

export default {
  name: 'objectList',
  components: {
    JNavbar,
    JFooter,
    Service,
    LLoading1,
    ScrollTop,
    InputSearch,
//    ChatBox,
    Item,
    ShopArticle,
    Visualizer,
    Reload
  },
  data () {
    return {
      loading:false,
      infos:{ 
          list:[
            //{nomProduit:"un nom hypermeglong pourvous goyave",city:'Mbalmayo',shop:'MMnM_ MMkolM_Camp Sonel Oyom-Abang',price:"3000000",rate:5}
          ]
          ,scroll:0,nbrRow:[0],
          city:'Yaounde',type:'Téléphone',search:''
        }
    }
 },
 computed: {
  moreVisible () {
    return 16*this.infos.nbrRow.length < this.infos.list.length ? true : false
  }
 },
 methods: {
  scrollingTop () {
    this.$refs.main.scrollTo({top:0,behavior:'smooth'})
  },
  showScrollTop () {
    this.$refs.main.scrollTop > 700 ? this.$refs.scrollTop.show() : this.$refs.scrollTop.hide()
  },
  moreResults () {
    this.infos.scroll = this.$refs.main.scrollTop
    if(this.moreVisible) this.infos.nbrRow = this.infos.nbrRow.concat(this.infos.nbrRow)
  },
  search (index,value) {
    this.infos[index] = value
    //console.log(index+':'+value)
    this.getObjects()
  },
  removeAccents (str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
  },
  shuffleArray(array) { // Fisher-Yates algorithm
    let i =0, max = array.length
    for (i = max - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  },
  setList (data) {
    if(data.length == 0) return []
    data = data.sort((a,b)=> a.distance - b.distance) // the closer the upper in the table
    let i = 0, max = data.length, resultTop=[],resultsRemain=[]
    let id = data[0].session+data[0].owner, k = 0
    for(i=0;i<max;i++) {
      let newId = data[i].session+data[i].owner
      //console.log(newId+'-----------'+id)
      if (newId == id && k<3) {
        resultTop.push(data[i])
        k++
      }
      else if (newId == id && k >= 3) {
        resultsRemain.push(data[i])
      }
      else /*if (data[i].session+data[i].owner != id )*/ {
        resultTop.push(data[i])
        id = newId
        k=1
      }
    }
    //console.log(resultTop)
    //console.log(resultsRemain)
    return resultTop.concat(this.shuffleArray(resultsRemain))
  },
  addViews () {
    const axios = require('axios')
    axios.post(this.$store.state.baseUrl + 'favorite.php', {
      type:"addView",
    })
      .then(() => {
        //console.log('done')
      })
      .catch((error) => {
        console.log(error)
      })
  },
  getProducts () {
    this.loading = true
    this.addViews() // add number of research
    const axios = require('axios')
    let coords = this.$store.state.coords.longitude === 0 ? {longitude:11.5048448, latitude:3.8731776, accuracy:8533} : this.$store.state.coords
    axios.post(this.$store.state.stockwareUrl + 'api/admin/sassayerProdS.php', {
      type:"research",
      search:this.infos.search == '' ? '.' : this.infos.search.toLowerCase(),
      longitude:coords.longitude,
      latitude:coords.latitude,
      accuracy:coords.accuracy
    })
      .then(({data}) => {
        /*data = data.concat(data)
        data = data.concat(data)
        data = data.concat(data)*/
        //console.log(data)
        data = this.setList(data) // organizing results order
        this.infos.scroll = 0
        this.infos.nbrRow = [0]
        this.$refs.main.scrollTo({top:0})
        let k = this
        this.infos.list = []
        this.$nextTick(()=>{
          k.infos.list = data
        })
        //--- updating data
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.$refs.reload.show()
        this.loading = false
      })
    }
  },
  watch: {
    '$store.state.toSearch' (newVal) {
      if(newVal.page==="objectList") {
        this.infos.search = newVal.search
        this.getProducts()
      }
    }
  },
  mounted () {
    if(this.$store.state.objectList.list.length == 0 && 
      this.$store.state.objectList.search == '') this.getProducts ()
    
    if(this.$route.params.token !== undefined && !this.$store.state.user.connected) { // s'il y'a un token et non inscrit afficher inscription
        this.$store.commit('setLogDisplay',{
          inscription:true,
          connexion:false
        })
    }
      
  },
  updated () {
    window.scrollTo(0,0)
    setTimeout(()=> {this.$refs.main.scrollTo({top:this.infos.scroll,behavior:'smooth'})},0)
  },
  beforeRouteEnter(to, from, next) {
    if(typeof from.name == 'undefined') {
      let token = to.params.token == undefined ? -1 : to.params.token
      next( {name:'firstPage',params:{next:to.name,token:token}})
    }
    else {
      next ( vm => {
        vm.infos = vm.$store.state.objectList
        vm.$store.commit('setSearch',{page:'objectListSender',search:vm.$store.state.objectList.search})
      })
    }
  },
  beforeRouteLeave (/*to, from*/) {
    
    if(this.$store.state.logDisplay.inscription || this.$store.state.logDisplay.connexion) {
      this.$store.commit('setLogDisplay',{
        inscription:false,
        connexion:false
      })
      return false
    }
    //----- closing ---------------------
    if(this.$refs.visualizer.Opacity == 1 || this.$refs.item.Display || this.$refs.reload.Display ) {
      this.$refs.visualizer.close()
      this.$refs.item.close()
      this.$refs.reload.close()
      return false
    }
    if(this.$store.state.DisplayProfile) {
      this.$store.commit('setDisplayProfile',false)
      return false
    }
    if(this.$store.state.DisplayQuizz) {
      this.$store.commit('setDisplayQuizz',false)
      return false
    }
    //------- saving before leaving -----------------------
    let newInfos = {...this.infos}
    newInfos.created = true
    newInfos.scroll = this.$refs.main.scrollTop
    this.$store.commit('setObjectList',newInfos)
    this.$store.commit('setSearch',{page:'objectListSender',search:''}) //clean it up before leaving
  }
}