export default {
    name: 'quizz',
    emit:["clicked","closing"],
    data () {
        return {
            Right:'-300px',
            Opacity:0,
            points:0
        }
    },
    methods: {
        show () {
            this.points = this.$store.state.quizzPoints[this.$store.state.quizzs[0].points]
            this.Opacity = 1
            this.Right = '0px'
        },
        close () {
            this.Opacity = 0
            this.Right = '-300px'
        },
        close1 () {
            this.Opacity = 0
            this.Right = '-300px'
            this.$emit('closing','failure')
        }
    }
  }
  