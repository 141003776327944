import LLoading1 from '@/components/global/lLoading/LLoading'
import Visualizer from '@/components/global/visualizer/Visualizer'
import Reload from '@/components/global/reload/Reload'
import ImgUpload from '@/components/global/imgUpload/ImgUpload'
import Confirm from '@/components/global/confirm/Confirm'

export default {
  name: 'profile',
  components: {
    LLoading1,
    Visualizer,
    Reload,
    ImgUpload,
    Confirm
  },
  data () {
    return {
      Display:false,
      loading:false,
      loadProfile:false,
      DisplayRank:false,
      ranking:[/*
        {nom:'Jean Dogoberg',pts:1000},{nom:'Jean Dogoberg',pts:1000},{nom:'Jean Dogoberg',pts:1000},
        {nom:'Jean Dogoberg',pts:1000},{nom:'Jean Dogoberg',pts:1000},{nom:'Jean Dogoberg',pts:1000},
        {nom:'Jean Dogoberg',pts:1000},{nom:'Jean Dogoberg',pts:1000},{nom:'Jean Dogoberg',pts:1000},
        {nom:'Jean Dogoberg',pts:1000},{nom:'Jean Dogoberg',pts:1000},{nom:'Jean Dogoberg',pts:1000},
        {nom:'Jean Dogoberg',pts:1000},
      */],
      bUrl:this.$store.state.baseUrl+'images/',
      rank:'??',
      total:'??',
      DisplayShare:false
    }
 },
 methods: {
  cliked (event) {
    if( event.target == this.$refs.main) this.close()
  },
  show () {
    this.Display = true
    this.$store.commit('setDisplayProfile',true)
  },
  close () {
    if(this.$refs.confirm.Display) {
      this.$refs.confirm.Display = false
      this.$store.commit('setDisplayProfile',true) // still open
      return
    }
    this.rank='??'
    this.total='??'
    this.ranking = []
    this.DisplayRank = false
    this.Display = false
    this.DisplayShare = false
    this.$store.commit('setDisplayProfile',false)
  },
  setColor (index) {
    return index < 3 ? 'profile1Color1' :
           index < 6 ? 'profile1Color2' :
           index < 10 ? 'profile1Color3' :
           'profile1Color4' 
  },
  deconnexion () {
    this.$refs.confirm.show("Voulez-vous vraiment vous déconnecter?")
  },
  disconnect (message) {
    if(message === 'yes') { // deconnexion
      localStorage.removeItem('user__')
      this.$store.commit('connexion',{connected: false,id:'',name:'',tel:'',img:''})

      localStorage.removeItem('favorites__')
      this.$store.commit('setFavorites',[])

      //----- closing ---------
      this.$refs.confirm.Display = false
      this.close()
    }
  },
  changeProfile (img) {
        if(this.loadProfile) return
        this.loadProfile = true

        let formData = new FormData()
        formData.append('image', img.file)
        formData.append('userId', this.$store.state.user.id)
        formData.append('type', 'saveImg')
        const axios = require('axios')
        axios.post(this.$store.state.baseUrl + 'inscription.php', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        })
        .then(({data}) => {
          let user = {...this.$store.state.user}
          user['img'] = data
          localStorage.setItem('user__',JSON.stringify(user))
          this.$store.commit('connexion',user)
          this.loadProfile = false
        })
        .catch((error) => {
          console.log(error)
          this.loadProfile = false  
        })
    },
    seeRank () {
      this.DisplayRank = true
      if(this.rank == '??') this.getRanks()
    },
    getRanks () {
      if(this.loading == true) return
      this.loading = true
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'favorite.php', {
        type:'pointDatas',
        userId: this.$store.state.user.id,
      })
        .then(({data}) => {
          this.total = data.total
          this.rank = data.user[0].rank
          this.ranking = data.ranking
          this.$store.commit('setUserPts',data.user[0].points)
          this.DisplayRank = true
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    share () {
      if(typeof this.$store.state.user.shareToken !== "string" || this.$store.state.user.shareToken == 0) {
        this.getToken()
      }
      this.DisplayShare = !this.DisplayShare
    },
    getToken () {
      if(this.loading == true) return
      this.loading = true
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'favorite.php', {
        type:'getShareToken',
        userId: this.$store.state.user.id,
      })
        .then(({data}) => {
          let user = {...this.$store.state.user}
          user['shareToken'] = data
          localStorage.setItem('user__',JSON.stringify(user))
          this.$store.commit('connexion',user)
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    }
 },
 watch: {
   '$store.state.DisplayProfile' (newVal) {
      if(!newVal) this.close()
   }
 }
}
