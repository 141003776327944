<template>
    <div class="firstPage">   
    </div>
</template>
<style>
.firstPage {

}
</style>
<script>
// this component allows to ensure that on the first load of the router event are active
// so we redirect once to this page after loading
export default {
  name: 'Firstpage',
  mounted () {
      //if(this.$route.params.next == -1) this.$router.go(-1)
      if(!this.$store.state.firstPageLoaded) {
        this.$store.commit('setFirstPageLoaded',true)
        let pr = this.$route.params.token == -1 ? {name:this.$route.params.next} : 
          {name:this.$route.params.next,params:{token:this.$route.params.token}}
        this.$router.push(pr)
      }
      else window.history.go(-1)
  },
}

</script>
