<template>
    <div v-show="Display" class="shopArticleContainer">
        <div class="shopArticleHeader">
            <div class="shopArticleHF"><img src="./img/list.svg" />{{ infos.shop }}</div>
            <div>
                <input @input="searchText" v-model="word" placeholder="Rechercher" type="search"
                ref="input" @search="$refs.input.blur()" />
                <Choose @chosen="searchCat" keyName="categorie" :infos="categories" style="margin-left:10px" ref="choose"/>
            </div>
            <AndroidButton @clicked="close"><img src="./img/cross.svg" /></AndroidButton>
        </div>
        <div class="shopArticle">
            <div class="shopArticleNothing" v-show="list.length==0" >Aucun résultat.</div>
            <AndroidButton v-for="(lt,index) in list.slice(0,16*nbrRow)" :key="index" radius="0em" class="shopArticleElt"
                @clicked="$refs.visualizer.open(lt.images,{...infos,...lt})"
            >
                <span :style="{backgroundImage:'url('+(lt.images.length == 0 ? '': lt.images[0] )+')'}" ></span>
                <div>
                    <div>{{ lt.nomProduit }} </div>
                    <span>{{ lt.prixProduit }} FCFA</span>
                    <p v-show="lt.disponible==1" >disponible</p>
                </div>
            </AndroidButton>
            <div class="shopArticleMore" @click="nbrRow++" v-show="nbrRow*16<list.length" ><span>Voir plus</span></div>
            <LLoading1 :visible="loading"/>
        </div>
        <Visualizer style="z-index:3" ref="visualizer"/>
        <Reload ref="reload" @reload="getProducts" style="z-index:3"/>
    </div>
</template>
<style src="./shopArticle.css">
</style>
<script src = "./shopArticle.js">
</script>
