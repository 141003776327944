import Form from './Form'

export default {
  name: 'admin',
  components:  {
    Form
  },
  data () {
    return {
      quizzs:[],
      daySearchs:[],
      amountUser:0,
      password:'',
      showQuizz:false,
      showStat:false,
      mois: ['Jan','Fév','Mar','Avr','Mai','Jun','Jul',
        'Aut','Sep','Oct','Nov','Déc']
    }
  },
  methods: {
    displaying(text) {
      this.text = text
      this.Display = true
    },
    clicked () {
      this.Display=false
      this.$emit('okay')
    },
    setDate (dat) {
      let d = new Date(dat)
      let m = d.getMonth()
      let dy = d.getDate()
      return this.mois[m+1]+' '+dy
    },
    saved(pl) {
      if(pl.type == 'add') this.quizzs.unshift(pl.inf)
      else {
        //let k = this
        //let quizzs = [...this.quizzs]
        //alert(pl.inf.index)
        this.quizzs[pl.inf.index] = pl.inf
        //this.quizzs = []
      }
    },
    deleteMe (inf,index) {
      this.loading = true 
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'admin.php',{
          type:'deleteQuizz',
          id:inf.id,
          password:this.password
      })
      .then(({data}) => {
        if(data === 'wrongPassword') alert('error')
        else if(data == 'done')this.quizzs.splice(index,1)
        console.log(data)
        this.loading = false
      })
      .catch((error) => {
        alert('error')
        console.log(error)
        this.loading = false  
      })
    },
    getStat () {
      this.loading = true 
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'admin.php',{
          type:'getStats',
          password:this.password,
      })
      .then(({data}) => {
        //console.log(data)
        if(data === 'wrongPassword') alert('error')
        else {
          this.daySearchs = data.stats
          this.amountUser = data.count
          this.showStat = true
        }
        this.loading = false
      })
      .catch((error) => {
        alert('error')
        console.log(error)
        this.loading = false  
      })
    },
    getInfos () {
      if(this.showQuizz == true) return
      this.loading = true 
      const axios = require('axios')
      axios.post(this.$store.state.baseUrl + 'admin.php',{
          type:'getQuizz',
          password:this.password,
      })
      .then(({data}) => {
        if(data === 'wrongPassword') alert('error')
        else {
          this.quizzs = data
          this.showQuizz = true
        }
        this.loading = false
      })
      .catch((error) => {
        alert('error')
        console.log(error)
        this.loading = false  
      })
    } 
  },
  mounted () {
    this.password = prompt('Entrer password','')
    this.getStat()
  }
}
